import React from "react"
// Components
import { SectionTitle } from "../../components/styled"
import Contact from "../../components/Contact"
import { Seo } from "../../components/seo"
const TermsOfUseEs = () => {
  return (
    <section className="container termsofuse">
      <div className="row row-en">
        <div className="termsofuse__about-security">
          <SectionTitle>
            Términos y Condiciones de Uso de Servicios de Quick Global México,
            S.A. de C.V. para los Clientes
          </SectionTitle>
          <hr className="u-line-bottom" />
          <p>
            Antes de solicitar nuestros servicios de reclutamiento y selección
            de personal que ofrece Quick Global México, S.A. de C.V. (en
            adelante “Nuestra Empresa”), le solicitamos cordialmente a nuestros
            clientes leer detenidamente, comprender y estar de acuerdo con estos
            Términos de Servicios de Quick Global México, S.A. de C.V. para los
            Clientes (en adelante “Los Términos”).
          </p>
          <h2>Clausula 1: Servicios de reclutamiento de personal</h2>
          <p>
            Los servicios de reclutamiento y selección de personal (en adelante
            “Los Servicios”) se refieren a los　siguientes servicios que presta
            “Nuestra Empresa” y los otros servicios relacionados.
          </p>

          <ol>
            <li>
              <b>Servicio de apoyo a reclutamiento y selección de personal</b>
            </li>
            <p style={{ marginTop: 0 }}>
              Es la denominación general de “Los Servicios” que presta “Nuestra
              Empresa”, incluyendo; cotejo de las condiciones de empleo deseadas
              por el usuario (solicitante de empleo) con los requerimientos de
              las empresas solicitantes de personal, entrevista y consulta sobre
              el cambio de empleo con nuestros asesores de empleo; introducción
              de ofertas de empleo y las actividades de apoyo para cambio de
              empleo; la representación del usuario en el trabajo de negociación
              con las empresas solicitantes de personal (desde el momento de
              solicitar el empleo hasta ingresar a trabajar); otros servicios
              que se consideren beneficiosos para el usuario.
            </p>
            <li>
              <b>Servicio de envío de Newsletter </b>
            </li>
            <p style={{ marginTop: 0 }}>
              Es un servicio de envío de boletín informativo que contiene la
              información útil para cambio de empleo.
            </p>
          </ol>
          <h2>Clausula 2: Usuario</h2>
          <ol>
            <li>
              El usuario es la persona que haya solicitado el uso de “Los
              Servicios” al dar su consentimiento a “Los Términos” y al manejo
              de información personal, además de ser aceptada para el uso de
              “Los Servicios” por “Nuestra Empresa”.
            </li>
            <li>
              Una vez que el usuario haya solicitado el uso de “Los Servicios”,
              se considerará que el usuario ha aceptado todo el contenido de
              “Los Términos” y el manejo de información personal.
            </li>
            <li>
              En caso de que falte algún dato en la información personal
              entregada por el usuario, habrá casos en que “Nuestra Empresa” no
              pueda ofrecer “Los Servicios”.
            </li>
          </ol>
          <h2>
            Clausula 3: Solicitud de uso de servicios e investigación al
            solicitante de los servicios
          </h2>
          <ol>
            <li>
              Hay casos que “Nuestra Empresa”　hace la investigación de
              antecedentes laborares del solicitante de “Los Servicios” según
              las necesidades. En este caso, se concluirá el trámite de la
              solicitud de uso de “Los Servicios” únicamente de los casos
              aprobados por “Nuestra Empresa”.
            </li>
            <li>
              En caso de que “Nuestra Empresa” considere inadecuado el
              solicitante para prestar “Los Servicios”, en cualquier momento
              podremos rechazar su uso de “Los Servicios”. No se obliga a
              “Nuestra Empresa” a contestar a alguna pregunta o consulta
              relacionada con la resolución, ni a atender de ninguna forma sobre
              el tema de resolución.
            </li>
            <li>
              “Nuestra Empresa” no se hace responsable de los daños causados
              relacionados con cada uno de los incisos anteriores.
            </li>
          </ol>
          <h2>Clausula 4: Manejo de información personal</h2>
          <p>
            “Nuestra Empresa” recolectará, utilizará, entregará y administrará
            adecuadamente la información personal del usuario de acuerdo con el
            reglamento del Manejo de Información Personal indicado más adelante.
          </p>
          <h2>
            Clausura 5: Cotejo con los requerimientos de la empresa solicitante
            de personal
          </h2>
          <p>
            “Nuestra Empresa” cotejará las condiciones de empleo que desee el
            usuario con los perfiles de puesto que pida la empresa solicitante
            de personal, sin embargo, no puede informar al usuario los criterios
            de evaluación ni, razones ni fundamentos de la decisión que se tome.
          </p>
          <h2>Clausula 6: Prohibiciones al Usuario</h2>
          <p>
            Para solicitar el uso de “Los Servicios” y/ o para utilizar “Los
            Servicios”, queda prohibido al usuario realizar los siguientes
            actos.
          </p>
          <ol>
            <li>
              Entregar o declarar la información falsa.
              <li>
                Difamar o insultar a “Nuestra Empresa”, los involucrados en el
                negocio de “Nuestra empresa”, los otros usuarios y/o los
                terceros.
              </li>
              <li>
                Infringir los derechos de propiedad, honor, privacidad, derechos
                de autor, marca comercial y propiedad intelectual de “Nuestra
                Empresa”, persona relacionada con nuestro trabajo, otros
                usuarios de “Nuestra Empresa” u otros terceros relacionados con
                nuestro trabajo.
              </li>
              <li>
                Obstaculizar la administración de “Los Servicios”, o dañar la
                credibilidad y reputación social de Nuestra Empresa.
              </li>
              <li>
                Filtrar y divulgar a terceros la información adquirida a través
                de “Los Servicios”.
              </li>
              <li>
                Presentar acto o posible acto contra el orden público y leyes.
              </li>
              <li>
                Pertenecer a una organización ilegal o de fuerza antisocial, dar
                beneficios a esas fuerzas o utilizarlas, o causar daños por esas
                fuerzas.
              </li>
            </li>
          </ol>
          <h2>
            Clausula 7: Suspensión o terminación de la prestación de Los
            Servicios
          </h2>
          <ol>
            <li>
              En caso de que “Nuestra Empresa” determine que el usuario violó
              “Los Términos” o que no se puede mantener una relación de
              confianza entre el usuario y “Nuestra empresa”, ésta puede
              suspender o terminar la prestación de “Los Servicios” sin ningún
              previo aviso al usuario en cuestión. “Nuestra Empresa” no asume
              ninguna responsabilidad ante cualquier daño derivado de lo
              mencionado antes. Cabe mencionar que lo establecido en este inciso
              no le impide a “Nuestra Empresa” demandar al usuario
              correspondiente por responsabilidad civil.
            </li>
            <li>
              Respecto a lo establecido en el inciso anterior,” Nuestra Empresa”
              no tiene obligación de contestar a las preguntas relacionadas con
              nuestra decisión ni atender de ninguna manera.
            </li>
          </ol>
          <h2>Clausula 8: Cambio o interrupción de Los Servicios</h2>
          <p>
            “Nuestra Empresa” puede cambiar o interrumpir temporalmente una o
            todas partes de “Los Servicios” sin previo aviso ni aprobación del
            usuario en alguno de los siguientes casos:
          </p>

          <ol>
            <li>
              Cuando sea necesario realizar mantenimiento, inspección,
              renovación, o medidas de urgencia del sistema para prestar “Los
              Servicios”.
            </li>
            <li>
              Cuando sea difícil seguir prestando “Los Servicios” debido a
              alguna falla del sistema y/o daños por virus inevitables por las
              medidas de seguridad normales o a causa de fuerza mayor como
              terremoto, incendios, falla de luz entre otros.
            </li>
            <li>
              Cuando “Nuestra Empresa” considere difícil prestar “Los Servicios”
              por alguna contingencia.
            </li>
          </ol>
          <h2>
            Artículo 9: Responsabilidad del Usuario y exclusión de
            responsabilidad
          </h2>
          <ol>
            <li>
              El uso de “Los Servicios” será bajo la propia responsabilidad del
              usuario.
            </li>
            <li>
              El usuario asumirá la responsabilidad completamente sobre la
              información que haya entregado a “Nuestra Empresa” para utilizar
              “Los Servicios”.
            </li>
            <li>
              El uso de “Los Servicios” no le garantiza al usuario la eficacia
              tal como tener éxito en conseguir empleo o cambiar de trabajo sin
              excepción, etc.
            </li>
            <li>
              El usuario, bajo su responsabilidad, deberá confirmar directamente
              con la empresa solicitante de personal las actividades que
              realizará y las condiciones laborales del puesto conseguido antes
              de ingresar al trabajo, e integrará a la empresa bajo las
              condiciones de empleo a voluntad (At-will Employment). “Nuestra
              Empresa” las confirmará con la empresa solicitante de personal,
              sin embargo, eso no garantiza las condiciones de empleo
              definitivamente.
            </li>
            <li>
              En caso de causar daños al usuario debido al uso de “Los
              Servicios”, “Nuestra Empresa” es responsable únicamente por los
              daños directamente causados por nuestra culpa y dentro del límite
              de los daños que surgen comúnmente y no es responsable de otro
              tipo de daños.
            </li>
          </ol>
          <h2>Artículo 10: Indemnización</h2>
          <p>
            En caso de que el usuario cause daños a “Nuestra Empresa”, personas
            relacionadas a “Nuestra Empresa” o terceros al momento de usar “Los
            Servicios”, el usuario es responsable de la indemnización, eximiendo
            a “Nuestra Empresa “de su responsabilidad.
          </p>
          <h2>Artículo 11: Modificación de los Términos</h2>
          <p>
            “Nuestra Empresa” puede modificar el contenido de los presentes
            Términos según las necesidades sin previa aprobación del usuario.
          </p>
          <h2>Artículo 12: Asuntos no descritos en los Términos</h2>
          <p>
            Los asuntos que no contempla “Los Términos” serán compartidos y
            conservados entre el usuario y “Nuestra Empresa” para su resolución
            en base a buena fe y voluntad.
          </p>
          <h2>＜Manejo de la Información Personal＞</h2>
          <p>
            “Nuestra Empresa” maneja, administra y utiliza información personal
            proporcionada por el usuario de la siguiente manera.
          </p>
          <h3>【Definición de información personal】</h3>
          <p>
            La información personal es la que se trata de un individuo que
            incluye nombre, domicilio, historial de carrera profesional, número
            de teléfono, cuenta de correo electrónico entre otros y que permite
            identificar a un individuo específico por la combinación de los
            elementos antes mencionados de la información personal.
          </p>

          <h3>【Objetivo de la recolección de la información personal】 </h3>
          <p>
            “Nuestra Empresa” recolectará información personal con el objetivo
            de ofrecer servicios de empleo apropiados. También en ocasiones se
            utiliza la información para solicitar una encuesta a los usuarios
            con la finalidad de mejorar nuestros servicios.
          </p>
          <h3>【Uso y entrega de la información personal】</h3>
          <p>
            Manejamos la información personal proporcionado por el usuario
            únicamente para lo mencionado en el “Objetivo de la Recolección de
            Información Personal”. Nunca revelamos la información personal a
            otras empresas ni a terceros sin previa aprobación del usuario.
          </p>
          <h3>
            【Entrega de la información personal a las empresas de nuestro
            grupo】
          </h3>
          <p>
            “Nuestra Empresa” en ocasiones entregará la información personal a
            las otras empresas de nuestro grupo siempre y cuando sea necesario
            para realizar “Los Servicios”. Al entregar la información personal,
            esta información será tratada en cumplimiento de la ley de
            protección de la información personal (Ley Federal de Protección de
            Datos Personales en Posesión de Particulares) y otras disposiciones
            legales relacionadas.
          </p>
          <ol className="u_mb30">
            <li>
              La entrega a las siguientes empresas del grupo es objetivo de uso.
              <ul>
                <li>Quick Global Co., Ltd.</li>
                <li>QUICK USA, Inc (USA)</li>
                <li>Center People Appointments Ltd. (Reino Unido)</li>
              </ul>
            </li>
            <li>
              Los datos personales que se entregarán a las empresas de nuestro
              grupo son los siguientes: <br />
              Nombre, domicilio, perfil académico y profesional, entre otros.
            </li>
            <li>
              Los medios de entrega de los datos personales a las empresas de
              nuestro grupo son los siguientes: <br /> 1. Teléfono 2. Correo
              electrónico 3. Servicio postal 4. Documentos en escrito 5. Mensaje
              verbal
            </li>
            <li>
              Se puede suspender la entrega de los datos personales a las
              empresas de nuestro grupo según lo solicite el usuario.
            </li>
          </ol>
          <h3>【Administración de la información personal】</h3>
          <p>
            Contra los riesgos del acceso ilícito a la información personal, la
            pérdida, el daño, la modificación o la fuga de ésta, el área de
            administración se esforzará en tomar las medidas de seguridad
            necesarias, continuas y razonables en aspectos de organización,
            técnico y físico en instalaciones y equipos.
          </p>
          <h3>【Administrador】</h3>
          <p>
            Nuestra Empresa asigna al responsable del área de administración
            como administrador de la información personal para esforzarse en la
            protección adecuada de la información personal.
          </p>
          <h3>【Subcontratación externa】</h3>
          <p>
            En el momento de subcontratar a un proveedor externo en los
            servicios de mejoramiento, mantenimiento del sistema y procesamiento
            de la información, en ocasiones también se subcontrata el manejo de
            la información personal. Por supuesto, se subcontrata este servicio
            después de celebrar el contrato de confidencialidad.
          </p>
          <h3>【Plazo de conservación de la información personal】</h3>
          <p>
            Se eliminará la información personal desde el punto de vista de
            protección de la misma, una vez que transcurra el tiempo adecuado
            que considera Nuestra Empresa. Sin embargo, no se devolverán los
            documentos físicos como CV o perfil profesional que nos han enviado
            por servicio postal o que nos han traído físicamente a Nuestra
            Empresa independientemente de que si se haya presentado o no a
            alguna empresa para búsqueda de empleo, ni sin importar que se haya
            contratado o no por alguna empresa a través de Servicios. Nuestra
            Empresa destruirá bajo nuestra responsabilidad estos documentos
            junto con la información personal.
          </p>
          <h3>
            【Modificación, revelación o eliminación (suspensión de uso) de la
            información personal】
          </h3>
          <p>
            En caso de que recibamos la solicitud de modificar, revelar o
            eliminar (suspender el uso) la información personal que nos han
            entregado, una vez confirmado que el solicitante es el propio
            usuario, tomaremos acciones correspondientes inmediatamente. Sin
            embargo, en caso de que esto se pueda obstaculizar nuestro trabajo o
            que sea necesario conservar el registro de trabajo por las leyes
            federales o estatales, puede ser que no podamos responder a la
            solicitud de modificación, agregación o eliminación (suspender el
            uso).
          </p>
          <h3>【Cambios y actualizaciones en los términos y condiciones 】</h3>
          <p>
            Los presentes términos y condiciones han tenido cambios y
            actualizaciones en la fecha 1 de abril de 2025. Y es posible que en
            un futuro reciba cambios y actualizaciones, las cuales serán válidas
            a partir de que sean publicadas en la presente página web.
          </p>
        </div>
        <Contact lang={"es"} />
      </div>
    </section>
  )
}

export default TermsOfUseEs

export const Head = () => {
  return (
    <Seo
      title="QUICK GLOBAL MEXICO, S.A. Terminos de uso "
      description="Términos y Condiciones de Uso de Servicios de Quick Global México, S.A. de C.V. para los Clientes"
      url={`https://www.919mexico.com/es/termsofuse`}
    />
  )
}
